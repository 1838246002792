@tailwind base;
@tailwind components;
@tailwind utilities;

/* purgecss start ignore */

.html {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

/* File Upload */
.fileContainer {
  box-shadow: none !important;
  padding-top: 0 !important;
}

.fileContainer .chooseFileButton {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  background-color: #ff6b00 !important;
  border-radius: 0.25rem !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

/* Ant Design Overrides */
.ant-select-selection.ant-select-selection--multiple {
  padding-top: 0.75rem !important;
  padding-bottom: 0.95rem !important;
  padding-left: 0.75rem !important;
}

/* table */

.hide-expander > .ant-table-row-expand-icon-cell > div {
  visibility: hidden !important;
}

.ant-table-expand-icon-th {
  border: none !important;
  background-color: white !important;
}

.ant-table-row-expand-icon-cell {
  border: none !important;
  padding: 0.75rem 0.25rem !important;
  background-color: white !important;
}

.ant-table-expanded-row {
  background-color: white !important;
}

.ant-table-thead > tr > th,
.ant-table-expanded-row > td {
  background-color: white !important;
  padding: 0.75rem 0.25rem !important;
}

.ant-table-expanded-row-level-1 td:first-child {
  border-bottom: none;
}

.ant-table-placeholder {
  z-index: 0 !important;
}

/* end table - */

/* tabs */

.ant-tabs-nav .ant-tabs-tab {
  padding: 12px 0 !important;
  font-weight: 400 !important;
}

/* end tabs */

/* date picker */

.ant-calendar-picker-input {
  border: none !important;
}

/* end date picker */

/* purgecss end ignore */
